import { Injectable } from '@angular/core';
import { Token, User } from '../models/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtService {
    public get isTokenExpired() {
        return this.jwtHelper.isTokenExpired(this.getJwt());
    }

    public get groups(): string[] {
        const token = this.getJwt();
        if (!token) return [];

        const tokenDecoded = this.decodeToken(token);
        const roles = tokenDecoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

        if (Array.isArray(roles)) return roles;
        return roles ? roles.split(',').map((role: string) => role.trim()) : [];
    }

    public get name(): string | "" {
        const token = this.getJwt();
        if (!token) return "";

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] || "";
    }

    public get email(): string | "" {
        const token = this.getJwt();
        if (!token) return "";

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] || "";
    }

    public userIsInGroup(group: string) {
        const roles = this.groups;

        if (roles.length === 0) return false;

        return roles.includes(group.toUpperCase());
    }

    public get issuer(): string | "" {
        const token = this.getJwt();
        if (!token) return "";

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded.iss || "";
    }

    public get audience(): string | "" {
        const token = this.getJwt();
        if (!token) return "";

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded.aud || "";
    }

    public get expiration(): number | null {
        const token = this.getJwt();
        if (!token) return null;

        const tokenDecoded = this.decodeToken(token);
        return tokenDecoded.exp || null;
    }

    constructor(private jwtHelper: JwtHelperService) { }

    private decodeToken(token: string) {
        return this.jwtHelper.decodeToken(token) as Token;
    }

    private getJwt(): string | null {
        const userJson = localStorage.getItem("bfs-user");
        if (!userJson) return null;
        const user = JSON.parse(userJson) as User;
        return user.token;
    }
}