import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';

@Injectable()
export class AuthGuardService
{
    constructor(public auth: JwtService, public router: Router) { }
    canActivate(): boolean
    {
        if (!this.auth.isTokenExpired)

            return true;

        this.router.navigate(['login']);
        return false;
    }
}