import { Injectable } from "@angular/core";
import { MessageService } from 'primeng/api';

@Injectable()
export class ToastService
{
    constructor(private messageService: MessageService) { }

    showSuccess(summary: string, detail: string, key?: string)
    {
        this.messageService.add({ key: key, severity: 'success', summary: summary, detail: detail, life: 5_000, closable: false });
    }

    showInfo(summary: string, detail: string, key?: string)
    {
        this.messageService.add({ key: key, severity: 'info', summary: summary, detail: detail, life: 5_000, closable: false });
    }

    showWarn(summary: string, detail: string, key?: string)
    {
        this.messageService.add({ key: key, severity: 'warn', summary: summary, detail: detail, life: 5_000, closable: false });
    }

    showError(summary: string, detail: string, key?: string)
    {
        this.messageService.add({ key: key, severity: 'error', summary: summary, detail: detail, life: 5_000, closable: false });
    }

    clear(toastKey?: string)
    {
        if (toastKey)
            this.messageService.clear(toastKey);
        else
            this.messageService.clear();
    }
}