import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/guards/jwt.guard';

const routes: Routes = [
    { 
        path: '', redirectTo: '/login', pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: async () => (await import('./auth/login/login.module')).LoginModule
    },
    {
        path: 'admin',
        loadChildren: async () => (await import('./admin/admin.module')).AdminModule,
        canActivate: [() => inject(AuthGuardService).canActivate()]
    },
    {
        path: 'expositor',
        loadChildren: async () => (await import('./expositor/expositor.module')).ExpositorModule,
        // canActivate: [() => inject(AuthGuardService).canActivate()]
    },
    {
        path: 'credencial',
        loadChildren: async () => (await import('./credencial/credencial.module')).CredencialModule,
        // canActivate: [() => inject(AuthGuardService).canActivate()]
    },
    {
        path: 'montadora',
        loadChildren: async () => (await import('./montadora/montadora.module')).MontadoraModule,
        // canActivate: [() => inject(AuthGuardService).canActivate()]
    },
    {
        path: 'prestador-servico',
        loadChildren: async () => (await import('./prestador-servico/prestador-servico.module')).PrestadorServicoModule,
        // canActivate: [() => inject(AuthGuardService).canActivate()]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
