import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const userJson = localStorage.getItem("bfs-user");
        if (!userJson)
            return next.handle(req);

        const user = JSON.parse(userJson) as User;

        const dupReq = req.clone({
            headers: req.headers.set('authorization', `Bearer ${user.token}`)
        });
        return next.handle(dupReq);
    }
}
