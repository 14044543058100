import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
    private config = inject(PrimeNGConfig);
    public date: Date | undefined;

    ngOnInit(): void
    {
        this.config.setTranslation({
            startsWith: "Inicia com",
            contains: "Contém",
            notContains: "Não contém",
            endsWith: "Termina com",
            equals: "Igual",
            notEquals: "Não é igual a",
            noFilter: "Sem filtro",
            lt: "Menor que",
            lte: "Menor que ou igua a",
            gt: "Maior que",
            gte: "Maior que ou igual a",
            is: "É",
            isNot: "Não é",
            before: "Antes",
            after: "Depois",
            dateIs: "Data é",
            dateIsNot: "Data não é",
            dateBefore: "Data é antes",
            dateAfter: "Data é depois",
            clear: "Limpar",
            apply: "Aplicar",
            matchAll: "Igual a todos",
            matchAny: "Igual a um",
            addRule: "Adicionar regra",
            removeRule: "Remover regra",
            accept: "Sim",
            reject: "Não",
            choose: "Escolha",
            upload: "Upload",
            cancel: "Cancelar",
            dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["D","S","T","Q","Q","S","S"],
            monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            dateFormat: "dd/mm/yy",
            firstDayOfWeek: 0,
            today: "Hoje",
            weekHeader: "Fr",
            weak: "Fraco",
            medium: "Médio",
            strong: "Forte",
            passwordPrompt: "Insira uma senha",
            emptyMessage: "Nenhum resultado encontrado",
            emptyFilterMessage: "Nenhum resultado encontrado"
        });
    }
}