import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError, of, throwError } from "rxjs";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor
{   
    private router = inject(Router);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(catchError((err: HttpErrorResponse) =>
        {
            if ([401, 403].includes(err.status))
                this.router.navigate(['admin', 'sem-autorizacao']);
            return throwError(() => err);
        }))
    }
}