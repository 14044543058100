import { Injectable, signal } from '@angular/core';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService 
{
    public user = signal<User | null>(null);
    public loading = signal<boolean>(false);

    constructor() { }

    public setUser({ nome, email, token }: User)
    {
        const newUser: User = {
            nome: nome,
            email: email,
            token: token
        };

        this.user.set(newUser);

        localStorage.setItem("bfs-user", JSON.stringify(newUser));
    }

    public getUser(): User | null
    {
        const user = localStorage.getItem("bfs-user");

        if (user)
            return JSON.parse(user) as User;
        return null;
    }

    public startLoading()
    {
        this.loading.set(true);
    }

    public stopLoading()
    {
        this.loading.set(false);
    }
}
